.styles_rsbcText__16wA0 {
  font-family: 'Poppins-Regular';
  letter-spacing: 1px;
}

.styles_rsbcSliderText__3h08j {
  font-family: 'Poppins-Regular';
  letter-spacing: 1px;
}

.rsbcSliderText {
  font-family: 'Poppins-Regular';
  letter-spacing: 1px;
}

.rsbcText {
  font-family: 'Poppins-Regular';
  letter-spacing: 1px;
}
