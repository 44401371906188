/* Aqui funciona */

.intercom-launcher {
  bottom: auto !important;
  top: 20px !important;
}

.intercom-lightweight-app-launcher {
  bottom: auto !important;
  top: 20px !important;
}

.intercom-launcher-frame {
  bottom: auto !important;
  top: 20px !important;
}

.intercom-launcher-badge-frame {
  bottom: auto !important;
  top: 20px !important;
}

[name='intercom-notifications-frame'] {
  bottom: auto !important;
  top: 80px !important;
  right: 10px !important;
  display: none !important;
}

/* Desde aqui estamos probando */
