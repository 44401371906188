.character {
  border: none;
  font-size: 20px;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-family: 'Poppins-Regular';
  color: #272729;
  background-color: #dbdbdb;
  display: flex;
  align-items: center;
  justify-content: center;
}
.error {
  border: 2px solid red;
}
.container {
  width: 100%;
  max-width: 60vw;
}

@media (max-width: 300px) {
  .container {
    max-width: 80vw;
  }
}
