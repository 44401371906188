@font-face {
  font-family: 'Gilroy-Bold';
  src: local('Gilgroy-Bold'), url('../../public/fonts/gilroy/Gilroy-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Gilroy-Medium';
  src: local('Gilgroy-Medium'), url('../../public/fonts/gilroy/Gilroy-Medium.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: local('Poppins-Bold'), url('../../public/fonts/poppins/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'Poppins-Light';
  src: local('Poppins-Light'), url('../../public/fonts/poppins/Poppins-Light.ttf') format('truetype');
  font-weight: lighter;
}

@font-face {
  font-family: 'Poppins-Medium';
  src: local('Poppins-Medium'), url('../../public/fonts/poppins/Poppins-Medium.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: local('Poppins-Regular'), url('../../public/fonts/poppins/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Poppins-SemiBold';
  src: local('Poppins-SemiBold'), url('../../public/fonts/poppins/Poppins-SemiBold.ttf') format('truetype');
}
